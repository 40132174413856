
import { defineComponent, PropType } from 'vue'
import CustomUI from './CustomUI.vue'

export default defineComponent({
  inheritAttrs: false,
  components: { CustomUI },
  props: {
    modelValue: {
      type: String
    },
    weekDays: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    min: String,
    max: String,
    labelledBy: String
  }
})
