
import { defineComponent, PropType, ref, computed } from 'vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'EurolinesDateSelect',
  props: {
    showReturnDate: {
      type: Boolean,
      required: true
    },
    departureDate: {
      type: String,
      required: true
    },
    returnDate: {
      type: String,
      required: true
    },
    availableDepartureWeekDays: {
      type: Array as PropType<string[]>,
      required: true
    },
    availableReturnWeekDays: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  components: { DatePicker },
  emits: ['update:departureDate', 'update:returnDate'],
  setup (props, { emit }) {
    const updateDepartureDate = (date: string) => {
      emit('update:departureDate', date)
    }

    const updateReturnDate = (date: string) => {
      emit('update:returnDate', date)
    }

    const dateMax = dayjs().add(180, 'days').format('YYYY-MM-DD')
    const dateMin = dayjs().format('YYYY-MM-DD')
    const departureDateLocal = ref(props.departureDate)
    const returnDateLocal = ref(props.returnDate)
    const dateMinReturn = computed(
      () => props.departureDate ? dayjs(props.departureDate).format('YYYY-MM-DD') : dateMin)

    return {
      updateDepartureDate,
      updateReturnDate,
      dateMax,
      dateMin,
      dateMinReturn,
      departureDateLocal,
      returnDateLocal
    }
  }
})
