<template>
  <div class="dates-box">
    <div>
      <label for="departure-date-label">Departure</label>
      <DatePicker
        class="small"
        required
        :max='dateMax'
        :min='dateMin'
        v-model='departureDateLocal'
        :weekDays='availableDepartureWeekDays'
        @update:modelValue='$emit("update:departureDate", $event)'
        :labelledBy='`departure-date-label`'
      />
    </div>
    <div v-if="showReturnDate">
      <label for="return-date-label">Return</label>
      <DatePicker
        class="small"
        required
        :max='dateMax'
        :min='dateMinReturn'
        v-model='returnDateLocal'
        :weekDays='availableReturnWeekDays'
        @update:modelValue='$emit("update:returnDate", $event)'
        :labelledBy='`return-date-label`'
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from 'vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'EurolinesDateSelect',
  props: {
    showReturnDate: {
      type: Boolean,
      required: true
    },
    departureDate: {
      type: String,
      required: true
    },
    returnDate: {
      type: String,
      required: true
    },
    availableDepartureWeekDays: {
      type: Array as PropType<string[]>,
      required: true
    },
    availableReturnWeekDays: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  components: { DatePicker },
  emits: ['update:departureDate', 'update:returnDate'],
  setup (props, { emit }) {
    const updateDepartureDate = (date: string) => {
      emit('update:departureDate', date)
    }

    const updateReturnDate = (date: string) => {
      emit('update:returnDate', date)
    }

    const dateMax = dayjs().add(180, 'days').format('YYYY-MM-DD')
    const dateMin = dayjs().format('YYYY-MM-DD')
    const departureDateLocal = ref(props.departureDate)
    const returnDateLocal = ref(props.returnDate)
    const dateMinReturn = computed(
      () => props.departureDate ? dayjs(props.departureDate).format('YYYY-MM-DD') : dateMin)

    return {
      updateDepartureDate,
      updateReturnDate,
      dateMax,
      dateMin,
      dateMinReturn,
      departureDateLocal,
      returnDateLocal
    }
  }
})
</script>
<style scoped>
label {
  font-weight: bold;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 23px;
  letter-spacing: 0.15px;
  display: inline-block;
  margin-bottom: 8px;
}

:deep(header) {
  margin: 0;
}

:deep(.calendar) {
  margin: 0;
}

.dates-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media (max-width: 768px) {
  .dates-box {
    grid-template-columns: 1fr;
  }
}
</style>
