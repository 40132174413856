
import { defineComponent, computed, ref, watch, nextTick, PropType } from 'vue'
import dayjs, { Dayjs } from 'dayjs'
import ArrowForward from '@/components/vectors/ArrowForward.vue'
import ArrowBackward from '@/components/vectors/ArrowBackward.vue'
import useCalendar from '@/composables/useCalendar'

export default defineComponent({
  components: { ArrowForward, ArrowBackward },
  props: {
    modelValue: String,
    min: String,
    max: String,
    weekDays: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props, { emit }) {
    const selectedDate = computed(() =>
      props.modelValue ? dayjs(props.modelValue) : null
    )
    const minDate = computed(() => (props.min ? dayjs(props.min) : null))
    const maxDate = computed(() => (props.max ? dayjs(props.max) : null))
    const { focusedDate, ...calendarComposable } = useCalendar(
      selectedDate.value || dayjs()
    )
    const dateList = ref()

    const selectDate = (day: number) => {
      emit(
        'update:modelValue',
        focusedDate.value.date(day).format('YYYY-MM-DD')
      )
    }

    const dayToDate = (day: number) => focusedDate.value.date(day)

    // eslint-disable-next-line complexity
    const isValidDate = (day: number) => {
      const date = dayToDate(day)

      if (props.weekDays.length > 0) {
        const weekDay = dayjs(date).locale('en').format('dddd')
        if (!props.weekDays.includes(weekDay.toString().toLowerCase())) {
          return false
        }
      }

      return (
        (!minDate.value || !date.isBefore(minDate.value, 'day')) &&
        (!maxDate.value || !date.isAfter(maxDate.value, 'day'))
      )
    }

    const isSelectedDate = (day: number) => {
      if (!selectedDate.value) return false

      const date = dayToDate(day)
      return date.isSame(selectedDate.value, 'day')
    }

    const isFocusedDate = (day: number) => {
      const date = dayToDate(day)
      return date.isSame(focusedDate.value, 'day')
    }

    watch(selectedDate, (newValue: Dayjs | null) => {
      if (!newValue) return

      focusedDate.value = newValue
    })

    watch(focusedDate, () => {
      nextTick(() => {
        if (
          !document.activeElement ||
          document.activeElement === document.body
        ) {
          dateList.value.querySelector('[tabindex="0"]').focus()
        }
      })
    })

    const DAY_NAMES = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ]

    const weekDayNameForNumber = (dayNumber: number): string =>
      DAY_NAMES[dayNumber]

    return {
      selectDate,
      focusedDate,
      dateList,
      dayToDate,
      isValidDate,
      isSelectedDate,
      isFocusedDate,
      minDate,
      maxDate,
      dayjs,
      weekDayNameForNumber,
      ...calendarComposable
    }
  }
})
